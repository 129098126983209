
import { StyleSheet, Text, View,Image ,TextInput,TouchableOpacity} from 'react-native';
import React, { useState, useEffect } from 'react';




export default function LoginScreen(){
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
  
    useEffect(() => {
     
    }, []);


 
  


  

      return (
          <View style={styles.allcontainer}>
              <View>
                      <View style={styles.container}>
                          <Image style={styles.imageAlign} source={require('./assets/Logo.png')}/>
                      </View>
                      <View style={styles.textAlign}>
                          <Text style={styles.textAlign1}>Valet <Text style={styles.textAlign2}>Bee's</Text></Text> 
                      </View>
                      <View style={styles.common}>
                          <View style={styles.login}>
                                <Text style={styles.logtext}>Login</Text>
                                <View style={styles.common}>
                                      <TextInput placeholder="UserName or Email"
                                        value={username}
                                        onChangeText={(text) => setUsername(text)} 
                                        style={styles.textinputalign} 
                                        placeholderTextColor='#727871'>
                                        </TextInput> 
                                </View>   
                                <View style={styles.common}>
                                      <TextInput placeholder="Password"
                                      style={styles.textinputalign}  value={password}
                                        onChangeText={(text) => setPassword(text)}
                                        secureTextEntry={true}
                                        placeholderTextColor='#727871'>
                                      </TextInput> 
                                </View> 
                                <View style={styles.common}>
                                <TouchableOpacity
                                  style={styles.logbutton}
                                  >
                                    <Text style={styles.logbuttonText}>Login</Text>
                                </TouchableOpacity>  
                                </View>
                          </View>
                      </View>
                      <View style={[styles.common]}>
                        
                          <Text style={styles.parkpart}>Parking Partner</Text>
                      
                      </View>
                      <View style={styles.finallogback}></View>
                </View>
        
          </View>
       
         );
}


const styles = StyleSheet.create({
    common:{
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
    },
    parkpart:{
      color:'white',
      fontSize:20,
      fontWeight:'400',
      marginTop:50
    },
    allcontainer: {
       flex:1,
       width:'100%',
       height:'100%'
    },
    container: {
      backgroundColor: "#FF9901",
      width:"100%",
      height:56,
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
    },
    imageAlign: {
      width:40,
      height:40
    },
    textAlign:{
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      marginTop:50,
  
    },
    textAlign1:{
       color:'#FF9901',
       fontSize:30,
       fontWeight:'bold',
       
  
    },
    textAlign2:{
      color:'black',
    },
  
    login:{
      marginTop:50,
       width:"90%",
       height:350,
       backgroundColor:"white",
       borderRadius: 35,
       padding:15,
       
    },
    logtext:{
      color: "#727871",
      fontSize: 24,
      fontWeight: "bold",
      marginTop:10,
      marginLeft:20
    },
    textinputalign:{
      borderWidth:1,
      borderColor:"#ccc",
      width:"90%",
      backgroundColor:"white",
      borderRadius:26,
      textAlign:"center",
      fontSize:15,
      paddingVertical: 13,
      marginTop:30,
      elevation: 6,
      color:'black'
     },
  
     logbutton: {
      width: '60%', 
      borderRadius: 30,
      backgroundColor: '#FF9901', 
      paddingVertical: 13,
      alignItems: 'center',
      marginTop:30
     },
     logbuttonText: {
      color: 'white', 
      fontSize: 16,
      fontWeight: 'bold',
     },
    
     finallogback:{
      borderTopLeftRadius: 50,
      backgroundColor: "#FF9901",
      borderTopRightRadius:50,
      width:"100%",
      height:800,
      position:'relative',
      bottom:0,
      top:-200,
      zIndex:-1
     }
  
  });
